<template>
  <div class="uploadRules">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">规则说明</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <!-- <p>
        欢迎优秀的您加入涩漫天堂，我们珍视每一位UP主，始终致力于为大家带来最优质的产品与服务。您的作品在涩漫天堂的售卖无时间、次数限制，销售的次数越多，获得的收入就会越多。无论您是在睡觉还是在工作，它们都将持续为您带来源源不断的收入。
      </p>
      <br /> -->
      <p>上传规则</p>
      <p>
        1.帖子标题不得超过150字。
      </p>
      <p>
       2.选择话题最多添加6个。
      </p>
      <p>3.发布内容只能为图片或者视频，视频清晰且时长不小于30秒，不得超过10分钟。
      </p>
      <p>4.视频中的当事人须满18岁以上，且当事人同意视频被上传分享。</p>
      <p>5.发布内容将由平台进行审核，审核时间为24小时内，请注意查收反馈。</p>
      <p>6.上传的作品可在“我的-创作中心”进行查看状态和编辑。</p>
      <br />
      <p>审核规则</p>
      <p>1.禁止直接搬运网络视频，重复率高且不容易通过，多次违规将降低账号推荐权重。</p>
      <p>
       2.禁止在视频/图片中添加个人联系方式或插入广告网址将不会通过审核。
      </p>
      <p>3.禁止上传幼女、人兽、真实强奸等侵害他人的视频。</p>
      <p>4.加强用户隐私性，允许原创视频为人物面部等重要部分添加遮挡或马赛克。</p>
      <p>
       5.上传的视频内容不符合上传要求将不会通过审核，如若退回视频未作修改再次发起审核将禁止上传。
      </p>
<!--      <br />-->
<!--      <p>定价规则</p>-->
<!--      <p>1.发布内容默认为免费，用户可根据作品内容质量调整为金币视频。</p>-->
<!--      <p>2.认证UP主发布原创举牌长视频，建议定价50-200金币</p>-->
<!--      <p>3.原创举牌作品，建议定价30-50金币</p>-->
<!--      <p>4.原创剪辑作品，建议定价10-20金币</p>-->
<!--      <p>5.非原创短片，建议定价1-10金币</p>-->
      <br />
      <br />
      <br />
      <br />
      <br />
      <!--            <div class="rules mt18">-->
      <!--                上传规则<br>-->
      <!--                1.帖子标题不得超过150字。<br>-->
      <!--                2.选择话题最多添加6个。<br>-->
      <!--                3.发布内容只能为图片或者视频，视频清晰且时长不小于30秒，不得超过10分钟。<br>-->
      <!--                4.视频中的当事人须满18岁以上，且当事人同意视频被上传分享。<br>-->
      <!--                5.发布内容将由平台进行审核，审核时间为24小时内，请注意查收反馈。<br>-->
      <!--                6.上传的作品可在“我的-创作中心”进行查看状态和编辑。-->
      <!--            </div>-->
      <!--            <div class="rules mt40">-->
      <!--                审核规则<br>-->
      <!--                1.禁止直接搬运网络视频，重复率高且不容易通过，多次违规将降低账号推荐权重。<br>-->
      <!--                2.禁止在视频/图片中添加个人联系方式或插入广告网址将不会通过审核。<br>-->
      <!--                3.禁止上传幼女、人兽、真实强奸等侵害他人的视频。<br>-->
      <!--                4.加强用户隐私性，允许原创视频为人物面部等重要部分添加遮挡或马赛克。<br>-->
      <!--                5.上传的视频内容不符合上传要求将不会通过审核，如若退回视频未作修改再次发起审核将禁止上传。<br>-->
      <!--            </div>-->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadRules {
  height: 100%;
  color: #000;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid #f3f3f3;
    //border-bottom: 1px solid #e6e6e6;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 16px;
      color: #333333;
      font-weight: 500;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    padding: 12px 16px;
    box-sizing: border-box;
    height: calc(100% - 45px);
    overflow-y: auto;
    background: #fff;

    color: #333333;
    line-height: 180%;
    font-size: 12px;

    //.rules {
    //    font-size: 12px;
    //    line-height: 32px;
    //}
    //
    //.mt18 {
    //    margin-top: 18px;
    //}
    //
    //.mt40 {
    //    margin-top: 40px;
    //}
  }
}
</style>
